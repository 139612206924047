export const special = `Type\tFrame\tDuration\tBody\tHead\tBurst\tTotal
Legendary\tAdaptive Fusion Rifle\t23.5\t14531\t14531\t15942\t341472
Legendary\tTecheun Force\t16.9\t23985\t23985\t24209\t405973
Legendary\tAggressive Fusion Rifle\t19.1\t15688\t15688\t16446\t299319
Legendary\tHigh-Impact Fusion Rifle\t33.3\t14647\t14647\t16146\t487600
Legendary\tThe Eremite\t24.1\t24043\t24043\t24245\t580244
Legendary\tPrecision Fusion Rifle\t25.8\t13734\t13734\t14888\t354281
Legendary\tHollow Words\t25.8\t15794\t15794\t17121\t407423
Legendary\tRapid-Fire Fusion Rifle\t23.7\t16052\t16052\t17565\t379940
Legendary\tScatter Signal\t20.6\t22154\t22154\t22154\t455928
Exotic\tBastion\t27.1\t18442\t18442\t24607\t499031
Exotic\tDelicate Tomb\t28.5\t16579\t16579\t17898\t472512
Exotic\tJotunn\t27.6\t17355\t17355\t20289\t478831
Exotic\tMerciless\t12.3\t25716\t25716\t28440\t316825
Exotic\tTelesto\t30.2\t15981\t15981\t16996\t481823
Exotic\tTessellation\t23.1\t14757\t14757\t61212\t341472
Legendary\tAdaptive Glaive\t23.2\t12191\t12191\t15567\t283314
Legendary\tLubrae's Ruin\t23.2\t14019\t14019\t17902\t325811
Legendary\tAggressive Glaive\t21.4\t12305\t12305\t16458\t263321
Legendary\tRapid-Fire Glaive\t18.5\t12363\t12363\t16025\t228839
Legendary\tGlaive Melee\tInfinite\t8737\t8737\t14899\tInfinite
Exotic\tEdge of Action\t17.0\t12492\t12492\t15567\t212486
Exotic\tEdge of Concurrence\t41.9\t14628\t14628\t34708\t612194
Exotic\tEdge of Intent\t27.2\t12101\t12101\t16458\t329152
Exotic\tVexcalibur\t18.4\t16947\t16947\t21860\t312156
Legendary\tDouble Fire Grenade Launcher\t31.9\t11883\t11883\t19925\t378582
Legendary\tWilderflight\t31.9\t13665\t13665\t22914\t435369
Legendary\tLightweight Grenade Launcher\t36.3\t9634\t9634\t15910\t350018
Legendary\tIgnition Code\t36.3\t11080\t11080\t18296\t402520
Legendary\tWave Frame Grenade Launcher\t35.3\t6831\t6831\t10954\t240983
Legendary\tExplosive Personality\t35.3\t7855\t7855\t12597\t277130
Exotic\tDead Messenger\t35.3\t6831\t6831\t10954\t240983
Exotic\tEx Diris\t20.3\t23045\t23045\t23045\t468280
Exotic\tWitherhoard\t210.0\t4864\t4864\t4864\t1021364
Exotic\tEriana's Vow\t19.5\t8982\t16455\t20098\t321368
Exotic\tArbalest\t20.8\t4895\t16841\t18534\t350296
Exotic\tLorentz Driver\t20.8\t4895\t16841\t18534\t350296
Exotic:Lagrangian Sight\tLorentz Driver\t20.8\t7343\t25262\t27801\t525444
Legendary\tAggressive Shotgun\t18.4\t15449\t17117\t23528\t314101
Legendary\tAstral Horizon\t18.4\t17766\t19685\t27057\t361216
Legendary\tLightweight Shotgun\t12.2\t17929\t19877\t27319\t241500
Legendary\tWastelander M5\t12.2\t20618\t22858\t31417\t277725
Legendary\tPrecision Shotgun\t19.3\t15090\t16718\t23779\t323153
Legendary\tCompass Rose\t19.3\t17353\t19225\t27346\t371626
Legendary\tPinpoint Slug Shotgun\t19.3\t9508\t16684\t23578\t321833
Legendary\tFortissimo-11\t27.5\t9539\t22447\t25106\t616847
Legendary\tRapid-Fire Shotgun\t15.5\t17611\t19520\t33045\t302358
Legendary\tUntil Its Return\t12.3\t25463\t28223\t38001\t347712
Exotic\tConditional Finality\t18.9\t27001\t27001\t51439\t509246
Exotic\tDuality\t13.6\t16326\t28651\t30674\t390793
Exotic:On Black Wings x5\tDuality\t13.6\t25305\t44408\t47545\t605729
Exotic\tLord of Wolves\t13.6\t17542\t25286\t33476\t344138
Exotic:Release the Wolves\tLord of Wolves\t11.3\t29474\t37570\t50719\t426042
Exotic\tThe Chaperone\t18.1\t10116\t17751\t25726\t321833
Exotic\tThe Fourth Horseman\t6.6\t23493\t32736\t120031\t216056
Legendary\tRocket-Assisted Sidearm\t32.0\t13232\t13717\t15631\t439228
Exotic\tBuried Bloodline\t43.1\t11271\t13592\t15683\t586233
Exotic\tForerunner\t28.4\t3466\t18040\t48254\t512325
Legendary\tAdaptive Sniper Rifle\t12.1\t5381\t17455\t26389\t211377
Legendary\tDefiance of Yasmin\t8.0\t8135\t31667\t31667\t253653
Legendary\tAggressive Sniper Rifle\t20.5\t4812\t16840\t27813\t344885
Legendary\tBite of the Fox\t16.4\t5995\t25174\t33376\t413862
Legendary\tRapid-Fire Sniper Rifle\t15.7\t5529\t19026\t28740\t298897
Legendary\tThe Supremacy\t21.7\t6242\t25123\t28740\t544419
Exotic\tCloudstrike\t22.6\t5139\t25971\t33843\t586155
Exotic\tBorealis\t19.5\t5062\t17714\t25892\t344885
Exotic\tIzanagi's Burden\t18.2\t4774\t15486\t28184\t281836
Exotic:Honed Edge x4\tIzanagi's Burden\t13.8\t3748\t22449\t61959\t309796
Legendary\tAdaptive Trace Rifle\t38.6\t6487\t8754\t10352\t337992
Legendary\tAcasia's Dejection\t37.9\t10043\t13552\t14107\t513619
Exotic\tAger's Scepter\t38.1\t8371\t11321\t13422\t430862
Exotic:Will Given Form\tAger's Scepter\t12.9\t17321\t23406\t23406\t301464
Exotic\tColdheart\t39.1\t9550\t12913\t15128\t504527
Exotic\tDivinity\t27.3\t5108\t5157\t5778\t148362
Exotic:Ember of Ashes\tPrometheus Lens\t47.2\t7713\t9625\t11842\t468941
Exotic\tRuinous Effigy\t37.0\t9021\t12106\t33701\t447939
Exotic\tThe Navigator\t38.1\t6445\t8660\t10305\t329770
Exotic\tWavesplitter\t39.1\t7173\t9692\t11478\t378781
Exotic:Supercharged Battery\tWavesplitter\t39.1\t10906\t14732\t17446\t575719`;
